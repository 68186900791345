/* You can add global styles to this file, and also import other style files */
body {
    margin: 0;
}
html {
    background-color: #f3f0f7;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

  
@tailwind base;
@tailwind components;
@tailwind utilities;